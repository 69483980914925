<template>
  <div class="app-home">
    <div class="app-home-head" v-show="isShowBtn">
      COA Import
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"
      >
        <i class="el-icon-arrow-left"></i>
      </span>
      <div class="app-home-head-username">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="confirm"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>

    <div class="app-home-table">
      <div class="table-name">
        <span>Coa List</span>
        <el-button v-if="userInfo.operateStatus" type="primary" @click="handleClick">
          <template>
            <i class="el-icon-plus"></i>
            <span>New</span>
          </template>
        </el-button>
      </div>

      <el-row style="padding: 10px"  v-show="isShowBtn">
        <el-col :span="5">
          <span>Product Name：</span>
          <el-autocomplete
            clearable
            v-model="searchData.name"
            :fetch-suggestions="nameSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectName"
          ></el-autocomplete>
        </el-col>
        <el-col :span="4">
          <span>Lot #：</span>
          <el-autocomplete
            clearable
            v-model="searchData.lot"
            :fetch-suggestions="lotSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectLot"
          ></el-autocomplete>
        </el-col>
        <el-col :span="5">
          <div>
            <span>Item Code：</span>
            <el-autocomplete
              popper-class="select-option"
              :popper-append-to-body="false"
              clearable
              v-model="searchData.code"
              :fetch-suggestions="codeSearch"
              placeholder="Please Input"
              @input="handleSelectCode"
            ></el-autocomplete>
          </div>
        </el-col>
        <el-col :span="3">
          <div style="margin-right: 20px">
            <el-radio-group
              v-model="dataType"
              size="medium"
              @change="dataTypeChange"
            > 
              <el-radio-button label="COA"></el-radio-button>
              <el-radio-button label="COA Archive"></el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            <el-button
              @click="handleSearch('查询')"
              type="primary"
              style="float: left"
              >Search</el-button>
            <el-button @click="handleSearch('重置')" style="float: left">Reset</el-button>
            <el-upload
              v-if="userInfo.operateStatus"
              action=""
              :auto-upload="true"
              :on-change="onChange"
              :limit="1"
              style="float: left; margin: 0px 0px 0px 10px"
            >
              <el-button type="success">Upload</el-button>
            </el-upload>
            <el-button @click="showPdf()" type="primary" style="float: left;margin-left:10px;">Upload PDF</el-button>
          </div>
        </el-col>
      </el-row>

      <el-row style="padding: 10px;margin-left:50px;" v-if="selectCoaIdList.length>0 && userInfo.operateStatus && isShowBtn">        
        <el-col :span="2">
          <div>
            <el-button type="primary" @click="addToLibrary">Upload to ERP Library</el-button>
          </div>
        </el-col>
      </el-row>
      <div
        :class="[{ 'table-list': true }, screen_ratio]"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        :element-loading-text="loadingText"
        style="height: 77vh"
      >
        <el-table
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{ background: '#FAFAFA' }"
           @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column type="index" label="Number" width="80"></el-table-column>
          <el-table-column label="TY #">
            <template slot-scope="scope">
              <span v-if="scope.row.isSet">{{scope.row.params.TyNumber}}</span>
              <span v-else>
                <el-input v-model="scope.row.params.TyNumber" @input="change($event,scope.$index,'TyNumber')"></el-input>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="itemCode" label="Item Code">
            <template slot="header">
              <span>Item Code</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': itemCodeSort,
                }"
                @click="handleSort('itemCode')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>

            <template slot-scope="scope">
              <span>{{scope.row.itemCode}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="Product Name"></el-table-column>
          <el-table-column prop="lot" label="Lot #"></el-table-column>
          <el-table-column label="China Lot" >
            <template slot-scope="scope">
              <span v-if="scope.row.isSet">{{scope.row.params.ChinaLotNumber}}</span>
              <span v-else>
                <el-input v-model="scope.row.params.ChinaLotNumber" @input="change($event,scope.$index,'ChinaLotNumber')"></el-input>
              </span>
            </template>
          </el-table-column>

          <el-table-column prop="verified" label="Verify">
            <template slot="header">
              <span>Verify</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': verifiedSort,
                }"
                @click="handleSort('verified')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>

            <template slot-scope="scope">
              <i
                :class="verify_show(scope.row).icon"
                :style="{ color: verify_show(scope.row).color }"
              ></i>
            </template>
          </el-table-column>

          <el-table-column label="Shipping Method">
            <template slot-scope="scope">
              <span v-if="scope.row.isSet">{{scope.row.params.ShippingMethod}}</span>
              <span v-else>
                <el-input v-model="scope.row.params.ShippingMethod" @input="change($event,scope.$index,'ShippingMethod')"></el-input>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="货运排序" >
            <template slot-scope="scope">
              <span v-if="scope.row.isSet">{{scope.row.params.FreightSorting}}</span>
              <span v-else>
                <el-input v-model="scope.row.params.FreightSorting" @input="change($event,scope.$index,'FreightSorting')"></el-input>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Quantity">
            <template slot-scope="scope">
              <span v-if="scope.row.isSet">{{scope.row.params.Quantity}}</span>
              <span v-else>
                <el-input v-model="scope.row.params.Quantity" @input="change($event,scope.$index,'Quantity')"></el-input>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="params.TotalNetWeight" label="总净重" ></el-table-column>
          <el-table-column prop="createTime" label="Create Time" width="140">
            <template slot="header">
              <span>Create Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': createTimeSort,
                }"
                @click="handleSort('createTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateTime" label="Edit Time" width="140">
            <template slot="header">
              <span>Edit Time</span>
              <span
                :class="{
                  'table-icon': true,
                  'table-icon-rotate': updateTimeSort,
                }"
                @click="handleSort('editTime')"
              >
                <i class="el-icon-top"></i>
              </span>
            </template>
            <template slot-scope="scope">
              <span>{{ getTime(scope.row.updateTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="updateName" label="Edit Name" ></el-table-column>
          <el-table-column label="Action" width="230px">
            <template slot-scope="scope">
              <span v-if="userInfo.operateStatus">
                <span class="table-edit" v-if="scope.row.isSet" @click="handleEdit(scope.$index)">Update</span>
                <span class="table-edit" v-else @click="saveEdit(scope.$index)">Save</span>
              </span>
              <span class="table-edit" @click="handleClick(scope.row, '编辑')">Edit</span>
              <span v-if="userInfo.operateStatus" class="table-edit" @click="copyItem(scope.row)">Copy</span>
              <span
                v-if="
                  username === 'JamesChen' ||
                  username === 'admin' ||
                  username === 'JiaZhang'
                "
                class="table-edit"
                style="color: red; margin-right: 8px"
                @click="deleteItem(scope.row)">Delete</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="table-pagition">
          <el-button type="danger" v-if="userInfo.operateStatus && isShowBtn" :disabled="selectCoaIdList.length == 0" plain @click="batchDelete()" style="float: left;">批量删除</el-button>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.pageIndex"
            :page-sizes="[100, 200, 300, 400]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
      <el-dialog
        :visible.sync="dialogPdfVisible"
        width="30%"
        :before-close="handlePdfClose">
        <div>
          <el-row>
            <el-col>
              <span class="formSpan">Item Code：</span>
            <el-autocomplete
              popper-class="select-option"
              :popper-append-to-body="false"
              clearable
              v-model="uploadPdf.itemCode"
              :fetch-suggestions="codeSearch"
              placeholder="Please Input"
              @input="handleSelectCode"
              class="formInput"
              disabled
            ></el-autocomplete>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
            <span class="formSpan">Lot #：</span>
            <el-autocomplete
            clearable
            v-model="uploadPdf.lot"
            :fetch-suggestions="lotSearch"
            placeholder="Please Input"
            popper-class="select-option"
            :popper-append-to-body="false"
            @input="handleSelectLot"
            class="formInput"
            disabled
          ></el-autocomplete>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <span class="formSpan">Upload PDF：</span>
              <el-upload
                ref="pdfupload"
                class="upload-demo"
                action="#"
                :http-request="onPdfChange"
                :before-upload="onBeforePdfUpload"
                :limit="1"
                :auto-upload="true"
                accept=".pdf"
                style="float:left;margin-left:10px;">
                <el-button size="small" type="primary">Upload PDF</el-button>
                <div slot="tip" class="el-upload__tip">只能上传.pdf文件</div>
              </el-upload>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogPdfVisible = false">取 消</el-button>
          <el-button type="primary" @click="addCoaToLibrary()">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import xlsx from "xlsx";
export default {
  name: "home",
  mixins: [base],
  data() {
    return {
      loading: false,
      loadingText:"Desperately loading...",
      tableData: [],
      total: 0,
      params: {
        pageIndex: 1,
        pageSorts: [
          {
            column: "createTime",
            asc: false,
          },
        ],
        pageSize: 100,
        itemCode: "",
        productName: "",
        lot: "",
      },
      searchData: {
        name: "",
        code: "",
        lot: "",
      },
      preview: {},
      createTimeSort: true,
      updateTimeSort: true,
      itemCodeSort: true,
      screen_ratio: "",
      nameList: [],
      lotList: [],
      codeList: [],
      getSearchList: true,
      uploadItemList:[],
      uploadItem:
      {
        number:"",//序号
        itemCode: "",//Item Code
        productName: "",//Product Name
        chinaProductName: "",//产品名称 --不上传
        lotNumber: "",//Lot # --不上传
        chinaLotNumber: "",//China Lot # 
        quantityKG: "",//Quantity (kg)
        standard1: "",//
        standard2: "",//
        standard3: "",//
        meshSize:"",//Mesh Size
        lossOnDrying: "",// Loss On Drying 
        bulkDensity: "",//Bulk Density
        totalHeavyMetals: "",//Total Heavy Metals
        color: "",//Color
        totalPlateCount: "",//Total PlateCount
        yeastAndMold: "",//Yeast &Mold
        specicalField1:"",//
        specicalField2:"",//
        specicalField3:"",//
        specicalField4:"",//
        specicalField5:"",//
        shippingMethod: "",//shippingMethod
        tyNumber: "",//TY#
        shippingDate: "",//Shipping Date
        freightSorting:"",//货运排序#
        totalNetWeight:"",//总净重
      },
      uploadBaseInfo:{
        tyNumber:"",//TY#
        shippingMethod:"",//shippingMethod
        freightSorting:"",//货运排序#
        shippingDate:"",//Shipping Date
        totalNetWeight:"",//总净重
      },
      dataType:"COA",
      isUpload:false,
      
      verifiedSort: true,
      
      
      formDataList:[],
      selectCoaIdList:[],
      
      formData: {
        id: 0,
        productName: "",
        itemCode: "",
        lot: "",
        jsonValue: {},
        updateName:"",
      },
      uploadPdf:{
        itemCode:"",
        lot:"",
        id:0,
        productName:"",
      },
      dialogPdfVisible:false,
      pdfUrl:{},
      selectList:[],
      isShowBtn:true,//是否来自外部的请求，false是，隐藏按钮和顶部内容
    };
  },
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      localStorage.setItem("loginUserNameKey", userInfo.username);
      if (userInfo) {
        return userInfo.username;
      } else {
        return "";
      }
    },
  },
  methods: {
    change(e,index,type){
      this.tableData[index].itemCode= this.tableData[index].itemCode+" ";
      this.tableData[index].params[type] = e;
    },
    handleEdit(index) {
      let data = this.tableData[index];
      data.isSet = false;
      data.itemCode = data.itemCode+" ";
     
      // console.info('aaa')
      // for (const i of this.tableData) {
      //   if (!i.isSet) return this.$message.warning('请先保存当前编辑项')
      // }
      this.$set( this.tableData, this.tableData[index], data ) 

    },
    saveEdit(index) {
      this.tableData.forEach((element,i)=>{
        if(i==index){
          element.isSet = true;
          element.itemCode = element.itemCode.trim();
          console.log(element)
          let jsonStr = JSON.stringify(element.params);
          console.log(jsonStr);
          this.formData.productName = element.productName;
          this.formData.itemCode = element.itemCode;
          this.formData.lot = element.lot;
          this.formData.jsonValue = jsonStr;
          this.formData.id = element.id;
          console.log(this.formData);
          this.$api.coa
          .editCoaUrl(this.formData)
          .then(() => {
            this.$message({
              type: "success",
              message: "SuccessFul",
            });
            this.$set(this.tableData,i,element);
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
          });
        }
      })
    },
    handleSelectionChange(selection) {
      console.log(selection)
      this.selectList = selection
      this.selectCoaIdList = selection.map(item => item.id)
      console.log("this.selectCoaIdList")
      console.log(this.selectCoaIdList)
    },
    addToLibrary(){
      this.$confirm(
        "Are you sure batch add. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        let params ={ids:this.selectCoaIdList,type:'coa'}
        console.log('addToLibrary======params')
        console.log(params)
        this.$api.batchAddLibrary(params).then((data) => {
          console.log('=====batchAddLibrary=========')
          console.log(data)
          this.$message({ type: "success", message: "batch Add SuccessFully" });
          const { href } = this.$router.resolve({
            name: "Library",
            query: { id: data[0].id,uploadPdf:1 },
          });
          window.open(href, '_blank');

        })
        .catch((err) => {
          console.log(err);
        });
      });
    },
    batchDelete(){
      console.log("this.selectCoaIdList")
      console.log(this.selectCoaIdList)
      this.$confirm(
        "This will permanently delete the data. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        this.loadingText = "Deleting in batch, please wait";
        this.$api.coa
          .batchDeleteUrl({"coaIds":this.selectCoaIdList})
          .then((data) => {
            console.log(data);
            this.$message({ 
              type: "success", 
              message: "Batch Delete SuccessFully"
            });
            this.loading = false;
            this.getData(this.params);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    handleSelectName() {
      // this.handleSearch("查询");
    },
    handleSelectLot() {
      // this.handleSearch("查询");
    },
    handleSelectCode() {
      // this.handleSearch("查询");
    },
    nameSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeCoaListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.productType });
      });
      // var restaurants = this.nameList;
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    lotSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeCoaListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.lot });
      });
      // var restaurants = this.lotList;
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    codeSearch(queryString, cb) {
      var codeList = JSON.parse(localStorage.getItem("itemCodeCoaListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    isRepeat(val) {
      let isRepeat = 0;
      this.tableData.forEach((v) => {
        if (val == v.itemCode) {
          isRepeat++;
        }
      });
      if (isRepeat > 1) {
        return "red";
      } else {
        return "black";
      }
    },
    getTime(time) {
      if (time) {
        return this.formatTime(time, `MM-dd-yyyy hh:mm`);
      } else {
        return "";
      }
    },
    logout() {
      let token = localStorage.getItem("specToken");
      let id = JSON.parse(localStorage.getItem("specUserInfo")).id;
      this.$api
        .logout({ id, token })
        .then(() => {
          this.$router.replace({ path: "/login" });
          this.$message({ type: "success", message: "Logout SuccessFully" });
          localStorage.clear("specToken");
          localStorage.clear("specUserInfo");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirm() {
      this.$confirm("Are you sure to quit?", "Tips", {
        confirmButtonText: "Confrim",
        cancelButtonText: "Cancel",
        distinguishCancelAndClose: true,
        type: "warning",
      }).then(() => {
        this.logout();
      });
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getData(this.params);
    },
    handleCurrentChange(val) {
      this.params.pageIndex = val;
      this.getData(this.params);
    },
    handleClick(val) {
      //console.log(val.jsonValue);
      var productType = "Powder";
      if (val.jsonValue) {
        var item = JSON.parse(val.jsonValue);
        productType = item.productType;
      }
      localStorage.setItem("searchDataCoaParamsKey", JSON.stringify(this.params));
      this.$router.push({
        path: "coa-item",
        query: { id: val.id, productType: productType },
      });
    },
    deleteItem(val) {
      this.$confirm(
        "This will permanently delete the data. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.$api.coa
          .deleteCoaItemUrl(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Delete SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    copyItem(val) {
      this.$confirm(
        "This will Copy the data. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        this.$api.coa
          .copyCoaItemUrl(val.id)
          .then((data) => {
            console.log(data);
            this.getData(this.params);
            this.$message({ type: "success", message: "Copy SuccessFully" });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    getData(params) {
      console.info('getData---->')
      this.loading = true;
      this.loadingText = "Desperately loading...";
      this.$api.coa
        .getCoaTableUrl(params)
        .then((data) => {
          console.log(data);
          this.params.pageIndex = data.pageIndex;
          this.params.pageSize = data.pageSize;
          this.total = data.total;
          this.tableData = data.records;
          
          for(var index in this.tableData){  
            this.tableData[index].params = JSON.parse(this.tableData[index].jsonValue);
            this.tableData[index].isSet = true;
          }

          this.loading = false;
          if (this.getSearchList) {
            this.nameList = data.records.map((v) => {
              return { value: v.productName };
            });
            this.lotList = data.records.map((v) => {
              return { value: v.lot };
            });
            this.codeList = data.records.map((v) => {
              return { value: v.itemCode };
            });
            this.getSearchList = false;
            this.$store.commit("setNameList", this.nameList);
            this.$store.commit("setCodeList", this.codeList);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSearch(type) {
      switch (type) {
        case "重置":
          {
            this.searchData = { name: "", code: "", lot: "" };
          }
          break;
        case "查询":
          {
            let { name, code, lot } = this.searchData;
            this.params.itemCode = code;
            this.params.productName = name;
            this.params.lot = lot;
            this.params.pageIndex = 1;

            // localStorage.setItem('searchParamKey',JSON.stringify({name,code}))
            this.getData(this.params);
          }
          break;
        default:
          break;
      }
    },
    verify_show(val) {
      switch (val.verified) {
        case "true":
          return { icon: "el-icon-success", color: "#67c23a" };
        case "false":
          return { icon: "el-icon-error", color: "#f56c6c" };
        default:
          return { icon: "el-icon-question", color: "#a6a9ad" };
      }
    },
    handleSort(val) {
      switch (val) {
        case "createTime":
          {
            this.createTimeSort = !this.createTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.createTime).getTime();
              let bTime = new Date(b.createTime).getTime();
              return this.createTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "editTime":
          {
            this.updateTimeSort = !this.updateTimeSort;
            this.tableData.sort((a, b) => {
              let fTime = new Date(a.updateTime).getTime();
              let bTime = new Date(b.updateTime).getTime();
              return this.updateTimeSort ? bTime - fTime : fTime - bTime;
            });
          }
          break;
        case "itemCode":
          {
            this.itemCodeSort = !this.itemCodeSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.itemCode;
              let bitemCode = b.itemCode;
              return this.itemCodeSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        case "verified":
          {
            this.verifiedSort = !this.verifiedSort;
            this.tableData.sort((a, b) => {
              let fitemCode = a.verified == undefined ? "" : a.verified;
              let bitemCode = b.verified == undefined ? "" : b.verified;
              return this.verifiedSort
                ? fitemCode.localeCompare(bitemCode)
                : bitemCode.localeCompare(fitemCode);
            });
          }
          break;
        default:
          break;
      }
    },
    getItemCodeListData(params) {
      this.$api.coa
        .getItemCodeCoaListUrl(params)
        .then((data) => {
          localStorage.setItem("itemCodeCoaListKey", JSON.stringify(data));
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    back() {
      this.$router.push({ path: "/home" });
    },
    formatDate(time) {
      return base.methods.formatDate({ date: time });
    },
    async onChange(file) {
      if(!this.isUpload){
        this.loading = true;
        this.loadingText = "Uploading, please wait";
        this.isUpload = true
        let itemCodes = []
        this.formDataList = []
        let dataBinary = await readFile(file.raw);
        let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
        let workSheet = workBook.Sheets[workBook.SheetNames[0]];
        const data = xlsx.utils.sheet_to_json(workSheet);
        console.info(data);
        //note: 1）manufacutring data是shipping date去掉日期，保留月份；
        //2）China Lot # "Quantity(kg)"、TY# 、Shipping Method、 货运排序# 、总净重 这六项，打印出来的COA不需要
        data.filter((item,y) => {
          // console.log("循环=="+y+"==");
          // console.log(item);
          if(y==0){
            var j = 0;
            for (var m in item) {
              j++;
              if (j == 1) {
                this.uploadBaseInfo.tyNumber = item[m]
              } 
              else if (j == 2) {
                this.uploadBaseInfo.shippingMethod = item[m]
              } 
              else if (j == 3) {
                this.uploadBaseInfo.freightSorting = item[m]
              } 
              else if (j == 4) {
                this.uploadBaseInfo.shippingDate = this.formatDate(item[m])
              }
              else{
                this.uploadBaseInfo.totalNetWeight = item[m]
              }
            }
            console.log("this.uploadBaseInfo")
            console.log(this.uploadBaseInfo)
          }
          else if(y>=2){
            let Newitem = {};
            var i = 0;
            console.log(item)
            for (var t in item) {
              i++;
              if (i == 1) {
                Newitem.number = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 2) {
                Newitem.itemCode = (item[t]+'').trim()=="空"?"":item[t];
                if(itemCodes.indexOf(Newitem.itemCode)<0){
                  itemCodes.push(Newitem.itemCode)
                }
              } else if (i == 3) {
                Newitem.productName = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 4) {
                Newitem.chinaProductName = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 5) {
                Newitem.lotNumber = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 6) {
                Newitem.chinaLotNumber = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 7) {
                Newitem.quantityKG = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 8) {
                Newitem.standard1 = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 9) {
                Newitem.standard2 = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 10) {
                Newitem.standard3 = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 11) {
                Newitem.meshSize = (item[t]+'').trim()=="空"?"":item[t];
              }else if(i==12){
                Newitem.lossOnDrying = (item[t]+'').trim()=="空"?"":item[t];
              }else if (i == 13) {
                Newitem.bulkDensity = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 14) {
                Newitem.totalHeavyMetals = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 15) {
                Newitem.color = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 16) {
                Newitem.totalPlateCount = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 17) {
                Newitem.yeastAndMold = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 18) {
                Newitem.specicalField1 = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 19) {
                Newitem.specicalField2 = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 20) {
                Newitem.specicalField3 = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 21) {
                Newitem.specicalField4 = (item[t]+'').trim()=="空"?"":item[t];
              } else if (i == 22) {
                Newitem.specicalField5 = (item[t]+'').trim()=="空"?"":item[t];
              }
            }
            Newitem.tyNumber = this.uploadBaseInfo.tyNumber
            Newitem.shippingMethod = this.uploadBaseInfo.shippingMethod
            Newitem.freightSorting = this.uploadBaseInfo.freightSorting
            Newitem.shippingDate = this.uploadBaseInfo.shippingDate
            Newitem.totalNetWeight = this.uploadBaseInfo.totalNetWeight
            this.uploadItemList.push(Newitem)
          }
        })
        console.log("this.uploadItemList")
        console.log(this.uploadItemList)
        console.log(itemCodes)
        this.$api.handleSpec.getInfoByItemCodes({"itemCodes":itemCodes})
        .then((data) => {
          console.log('getInfoByItemCodes')
          console.log(data)
          let oldItemCodeList=[]
          let newItemCodeList=[]
          this.uploadItemList.forEach((item)=>{
            oldItemCodeList.push(item.itemCode)
            data.forEach((element) => {
              if(item.itemCode == element.itemCode){
                this.formDataList.push(this.baseInfoItemCodeDetail(JSON.parse(element.jsonValue),item));
              }
            })
          });
          this.formDataList.forEach((element) => {
            newItemCodeList.push(element.itemCode)
          })
          let diffItemCode= oldItemCodeList.filter(function(val){
            return newItemCodeList.indexOf(val)===-1
          })
          console.log("diffItemCode")
          console.log(diffItemCode.toString())
          console.log("this.formDataList")
          console.log(this.formDataList)
          //#region 插入数据
          this.$api.coa.uploadCoaItem(this.formDataList)
          .then(() => {
            if(diffItemCode.length>0){
              this.$alert("Failed to upload itemCode："+ diffItemCode.toString(), 'Upload Tips', {
                confirmButtonText: '确定',
              });
            }
            else{
              this.$message({
                type: "success",
                message: "SuccessFul",
              });
            }
            this.handleSearch("查询");
            this.isUpload = false;
            this.loading = false;
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
            console.log(err);
            this.isUpload = false;
            this.loading = false;
          });
          //#endregion          
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: err,
          });
          console.log(err);
          this.loading = false;
          this.isUpload = false;
        });
      }
    },
    async onChange1(file) {
      if(!this.isUpload){
        this.loading = true;
        this.loadingText = "Uploading, please wait";
        this.isUpload = true
        this.formDataList = []
        let dataBinary = await readFile(file.raw);
        let workBook = xlsx.read(dataBinary, { type: "binary", cellDates: true });
        let workSheet = workBook.Sheets[workBook.SheetNames[0]];
        const data = xlsx.utils.sheet_to_json(workSheet);
        console.info(data);
        data.filter((item,y) => {
          console.log(y)
          let Newitem = {};
          var i = 0;
          console.log(item)
          for (var t in item) {
            i++;
            if (i == 3) {
              Newitem.oldItemCode = (item[t]+'').trim()=="空"?"":item[t];
            } else if (i == 4) {
              Newitem.newItemCode =(item[t]+'').trim()=="空"?"":item[t];
            }
          }
          this.uploadItemList.push(Newitem)
        })
        this.uploadItemList.forEach(item=>{
          item.newItemCode = item.oldItemCode + "&" + item.newItemCode
        })
        console.log("this.uploadItemList")
        console.log(this.uploadItemList)
        //#region 插入数据
        // this.$api.handleSpec.batchUpdateItemCode(this.uploadItemList)
        // .then(() => {
        //   this.$message({
        //     type: "success",
        //     message: "SuccessFul",
        //   });
        //   this.isUpload = false;
        //   this.loading = false;
        // })
        // .catch((err) => {
        //   this.$message({
        //     type: "error",
        //     message: err,
        //   });
        //   console.log(err);
        //   this.isUpload = false;
        //   this.loading = false;
        // });
        //#endregion
        // this.loading = false;
        // this.isUpload = false;
      }
    },
    dataTypeChange(){
      console.log("gg");
    },
    baseInfoItemCodeDetail(newVal,uploadItem){
      let formData = {
        productName: "",
        itemCode: "",
        lot: "",
        jsonValue: {},
      };
      let powerForm = {
        ChinaProductName:"",
        ChinaLotNumber:"",
        TotalNetWeight:"",
        FreightSorting:"",

        ShippingMethod:"",
        Quantity:"",
        TyNumber:"",

        BaseInfoEditPrintTitle: "",
        BaseInfoEditPrintTitleCheckbox: false,
        productType: "Powder",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        BaseInfoLot: "",
        BaseInfoCustomProductName: "",
        BaseInfoCountryofOrigin: "China",
        BaseInfoCustomerPONumber: false, //Checkbox
        BaseInfoCustomerPONumberText: "",
        BaseInfoCustomerCodeCheckbox: false,
        BaseInfoCustomerCode: "",
        BaseInfoCOfOFAndCOfOE: false, //Checkbox
        BaseInfoCOfOFAndCOfOEText: "",
        BaseInfoSAPNumber: false, //Checkbox
        BaseInfoSAPNumberText: "",
        BaseInfoManufacturingDate: "",
        BaseInfoExpirationDate: "",

        BaseInfoCustomRowKey1: "",
        BaseInfoCustomRowValue1: "",
        BaseInfoCustomRowKey2: "",
        BaseInfoCustomRowValue2: "",

        GeneralSpecIngredients: "",
        GeneralSpecProductGrade: "",
        GeneralSpecINCIName: "",
        GeneralSpecBotanicalName: "",
        GeneralSpecCommonNameOrOtherName: "",
        GeneralSpecPartUsed: "",
        GeneralSpecPercentage: "",
        GeneralSpecExtractionRatio: "",
        GeneralSpecImportantConstituentsInPlant: "",
        GeneralSpecHarvestMethod: false,
        GeneralSpecHarvestMethodText: "",
        GeneralSpecAppearance: "",
        GeneralSpecSolubility: "",
        GeneralSpecHomogeneity: "Completely homogenous",
        GeneralSpecExtractionProcess:
          "Controlled-temperature water extraction process, spray dried",
        GeneralSpecProcessingAid: "",
        GeneralSpecSolventForExtraction: "",
        GeneralSpecCarrier: "",
        GeneralSpecPesticides: "",
        GeneralSpecCertification: "",

        GeneralSpecCustomRowKey1: "",
        GeneralSpecCustomRowValue1: "",
        GeneralSpecCustomRowKey2: "",
        GeneralSpecCustomRowValue2: "",
        GeneralSpecCustomRowKey3: "",
        GeneralSpecCustomRowValue3: "",

        SpecificationSTANDARD1: "",
        SpecificationSTANDARD2: "",
        SpecificationSTANDARD3: "",
        SpecificationMeshSize: "",
        SpecificationLossOnDrying: "",
        SpecificationBulkDensity: "",
        SpecificationIdentification: "Conforms",
        SpecificationTotalHeavyMetals: "",
        SpecificationArsenic: "",
        SpecificationCadmium: "",
        SpecificationLead: "",
        SpecificationMercury: "",
        SpecificationColor: "",
        SpecificationOdor: "Compares to standard",
        SpecificationTaste: "Compares to standard",

        ResultSTANDARD1: "",
        ResultSTANDARD2: "",
        ResultSTANDARD3: "",
        ResultMeshSize: "",
        ResultLossOnDrying: "",
        ResultBulkDensity: "",
        ResultIdentification: "Passes",
        ResultTotalHeavyMetals: "",
        ResultArsenic: "",
        ResultCadmium: "",
        ResultLead: "",
        ResultMercury: "",
        ResultColor: "",
        ResultOdor: "Compares to standard",
        ResultTaste: "Compares to standard",

        TestMethodSTANDARD1: "",
        TestMethodSTANDARD2: "",
        TestMethodSTANDARD3: "",
        TestMethodMeshSize: "Metric Sieve",
        TestMethodLossOnDrying: "Draco-1.1.1.0",
        TestMethodBulkDensity: "USP <616>",
        TestMethodIdentification: "HPTLC",
        TestMethodTotalHeavyMetals: "USP <231>",
        TestMethodArsenic: "ICP-MS",
        TestMethodCadmium: "ICP-MS",
        TestMethodLead: "ICP-MS",
        TestMethodMercury: "ICP-MS",
        TestMethodColor: "Visual",
        TestMethodOdor: "Organoleptic",
        TestMethodTaste: "Organoleptic",

        SpecificationTotalPlateCount: "",
        SpecificationYeastAndMold: "",
        SpecificationEColi: "Negative",
        SpecificationSalmonella: "",
        SpecificationStaphAureus: "",
        SpecificationTotalColiforms: "",

        ResultTotalPlateCount: "",
        ResultYeastAndMold: "",
        ResultEColi: "Negative",
        ResultSalmonella: "Negative",
        ResultStaphAureus: "Negative",
        ResultTotalColiforms: "<10 MPN/g",

        TestMethodTotalPlateCount: "FDA-BAM",
        TestMethodYeastAndMold: "FDA-BAM",
        TestMethodEColi: "FDA-BAM",
        TestMethodSalmonella: "FDA-BAM",
        TestMethodStaphAureus: "FDA-BAM",
        TestMethodTotalColiforms: "FDA-BAM",

        SpecificationCustomRowKey1: "",
        SpecificationCustomRowValue1: "",
        ResultCustomRowKey1: "",
        ResultCustomRowValue1: "",
        TestMethodCustomRowKey1: "",
        TestMethodCustomRowValue1: "",

        SpecificationCustomRowKey2: "",
        SpecificationCustomRowValue2: "",
        ResultCustomRowKey2: "",
        ResultCustomRowValue2: "",
        TestMethodCustomRowKey2: "",
        TestMethodCustomRowValue2: "",

        SpecificationCustomRowKey3: "",
        SpecificationCustomRowValue3: "",
        ResultCustomRowKey3: "",
        ResultCustomRowValue3: "",
        TestMethodCustomRowKey3: "",
        TestMethodCustomRowValue3: "",

        SpecificationCustomRowKey4: "",
        SpecificationCustomRowValue4: "",
        ResultCustomRowKey4: "",
        ResultCustomRowValue4: "",
        TestMethodCustomRowKey4: "",
        TestMethodCustomRowValue4: "",

        SpecificationCustomRowKey5: "",
        SpecificationCustomRowValue5: "",
        ResultCustomRowKey5: "",
        ResultCustomRowValue5: "",
        TestMethodCustomRowKey5: "",
        TestMethodCustomRowValue5: "",

        MTSpecificationCustomRowKey1: "",
        MTSpecificationCustomRowValue1: "",
        MTResultCustomRowKey1: "",
        MTResultCustomRowValue1: "",
        MTTestMethodCustomRowKey1: "",
        MTTestMethodCustomRowValue1: "",

        MTSpecificationCustomRowKey2: "",
        MTSpecificationCustomRowValue2: "",
        MTResultCustomRowKey2: "",
        MTResultCustomRowValue2: "",
        MTTestMethodCustomRowKey2: "",
        MTTestMethodCustomRowValue2: "",

        MTSpecificationCustomRowKey3: "",
        MTSpecificationCustomRowValue3: "",
        MTResultCustomRowKey3: "",
        MTResultCustomRowValue3: "",
        MTTestMethodCustomRowKey3: "",
        MTTestMethodCustomRowValue3: "",
        
        genSpecCustomField: "",
        analysisCustomField: "",
        micTestsCustomField: "",
        note1:false,
        note2:false,
        note3:false,
        
        tableInput1:"",
        tableInput2:"",
        tableInput3:"",
        tableInput4:"",
        tableInput5:"",
        tableInputCustomField:"",
      };
      console.log("========newVal========");
      console.log(newVal);
      console.log(newVal.productType);
      //#region 组装来自spec的内容
      powerForm.GeneralSpecCustomRowKey1=newVal.generalSpecificationKey1;
      powerForm.GeneralSpecCustomRowValue1=newVal.generalSpecificationValue1;
      powerForm.GeneralSpecCustomRowKey2=newVal.generalSpecificationKey2;
      powerForm.GeneralSpecCustomRowValue2=newVal.generalSpecificationValue2;
      powerForm.GeneralSpecCustomRowKey3=newVal.generalSpecificationKey3;
      powerForm.GeneralSpecCustomRowValue3=newVal.generalSpecificationValue3;
      powerForm.SpecificationCustomRowKey1=newVal.specificationCustomerKey1;
      powerForm.SpecificationCustomRowValue1=newVal.specificationCustomerValue1;
      powerForm.TestMethodCustomRowKey1=newVal.testMethodCustomerKey1;
      powerForm.TestMethodCustomRowValue1=newVal.testMethodCustomerValue1;
      powerForm.SpecificationCustomRowKey2=newVal.specificationCustomerKey2;
      powerForm.SpecificationCustomRowValue2=newVal.specificationCustomerValue2;
      powerForm.TestMethodCustomRowKey2=newVal.testMethodCustomerKey2;
      powerForm.TestMethodCustomRowValue2=newVal.testMethodCustomerValue2;
      powerForm.SpecificationCustomRowKey3=newVal.specificationCustomerKey3;
      powerForm.SpecificationCustomRowValue3=newVal.specificationCustomerValue3;
      powerForm.TestMethodCustomRowKey3=newVal.testMethodCustomerKey3;
      powerForm.TestMethodCustomRowValue3=newVal.testMethodCustomerValue3;
      powerForm.SpecificationCustomRowKey4=newVal.specificationCustomerKey4;
      powerForm.SpecificationCustomRowValue4=newVal.specificationCustomerValue4;
      powerForm.TestMethodCustomRowKey4=newVal.testMethodCustomerKey4;
      powerForm.TestMethodCustomRowValue4=newVal.testMethodCustomerValue4;
      powerForm.SpecificationCustomRowKey5=newVal.specificationCustomerKey5;
      powerForm.SpecificationCustomRowValue5=newVal.specificationCustomerValue5;
      powerForm.TestMethodCustomRowKey5=newVal.testMethodCustomerKey5;
      powerForm.TestMethodCustomRowValue5=newVal.testMethodCustomerValue5;
      powerForm.MTSpecificationCustomRowKey1=newVal.microbiologicalCustomerKey1;
      powerForm.MTSpecificationCustomRowValue1=newVal.microbiologicalCustomerValue1;
      powerForm.MTTestMethodCustomRowValue1=newVal.microbiologicalTestMethodCustomerValue1;

      powerForm.GeneralSpecIngredients = newVal.ingredients;
      powerForm.GeneralSpecProductGrade = newVal.productGrade;
      powerForm.GeneralSpecINCIName = newVal.inciName;
      powerForm.GeneralSpecBotanicalName = newVal.botanicalName;
      powerForm.GeneralSpecCommonNameOrOtherName = newVal.commonOtherName;
      powerForm.GeneralSpecPartUsed = newVal.partUsed;
      powerForm.GeneralSpecPercentage = newVal.percentage;
      powerForm.GeneralSpecExtractionRatio = newVal.extractionRatio;
      powerForm.GeneralSpecImportantConstituentsInPlant = newVal.importantConstitueInPlant;
      powerForm.GeneralSpecAppearance = newVal.appearance;
      powerForm.GeneralSpecSolubility = newVal.solubility;
      powerForm.GeneralSpecProcessingAid = newVal.processingAid;
      if (!powerForm.GeneralSpecSolventForExtraction) {
        if (newVal.productType == "EHXTO") {
          powerForm.GeneralSpecSolventForExtraction = newVal.ehxtoSolventExtraction;
        } else {
          powerForm.GeneralSpecSolventForExtraction = newVal.solventForExtracti;
        }
      }
      if (!powerForm.GeneralSpecSolventForExtraction) {
        powerForm.GeneralSpecSolventForExtraction = "water";
      }
      powerForm.GeneralSpecCarrier = newVal.carrier;
      if (!powerForm.GeneralSpecPesticides) {
        if (newVal.productType == "EHXTO") {
          powerForm.GeneralSpecPesticides = newVal.ehxtoPesticides;
        } else {
          powerForm.GeneralSpecPesticides = newVal.pesticides;
        }
      }
      if (!powerForm.GeneralSpecPesticides) {
        powerForm.GeneralSpecPesticides = "Conforms to USP <565>";
      }
      powerForm.GeneralSpecCertification = newVal.certification;
      powerForm.SpecificationSTANDARD1 = newVal.specificationStandard1;
      powerForm.SpecificationSTANDARD2 = newVal.specificationStandard2;
      powerForm.SpecificationSTANDARD3 = newVal.specificationStandard3;
      powerForm.SpecificationMeshSize = newVal.specificationMeshSize;
      powerForm.SpecificationLossOnDrying = newVal.specificationLossonDrying;
      powerForm.SpecificationBulkDensity = newVal.specificationBulkDensity;
      powerForm.SpecificationTotalHeavyMetals = newVal.specificationTotalHeavyMetals;
      powerForm.SpecificationArsenic = newVal.specificationArsenic;
      powerForm.SpecificationCadmium = newVal.specificationCadmium;
      powerForm.SpecificationLead = newVal.specificationLead;
      powerForm.SpecificationMercury = newVal.specificationMercury;
      powerForm.SpecificationColor = newVal.specificationColor;
      powerForm.SpecificationOdor = newVal.specificationOrdor;
      powerForm.SpecificationTaste = newVal.specificationTaste;
      powerForm.TestMethodArsenic = newVal.testMethodArsenic;
      powerForm.TestMethodCadmium = newVal.testMethodCadmium;
      powerForm.TestMethodLead = newVal.testMethodLead;
      powerForm.TestMethodMercury = newVal.testMethodMercury;
      // powerForm.TestMethodColor = newVal.testMethodColor;
      powerForm.TestMethodOdor = newVal.testMethodOrdor;
      powerForm.TestMethodTaste = newVal.testMethodTaste;
      powerForm.TestMethodSTANDARD1 = newVal.testMethodStandard1;
      powerForm.TestMethodSTANDARD2 = newVal.testMethodStandard2;
      powerForm.TestMethodSTANDARD3 = newVal.testMethodStandard3;
      powerForm.SpecificationTotalPlateCount = newVal.specificationTotalPlateCount;
      powerForm.SpecificationYeastAndMold = newVal.specificationYeastAndMold;
      powerForm.SpecificationEColi = newVal.specificationEcoli;
      powerForm.SpecificationSalmonella = newVal.specificationSalmonella;
      powerForm.SpecificationStaphAureus = newVal.specificationStaphAureus;
      powerForm.SpecificationTotalColiforms = newVal.specificationTotalColiforms;
      powerForm.TestMethodTotalPlateCount = newVal.testMethodTotalPlateCount;
      powerForm.TestMethodYeastAndMold = newVal.testMethodYeastAndMold;
      powerForm.TestMethodEColi = newVal.testMethodEcoli;
      powerForm.TestMethodSalmonella = newVal.testMethodSalmonella;
      powerForm.TestMethodStaphAureus = newVal.testMethodTaphAureus;
      powerForm.TestMethodTotalColiforms = newVal.testMethodTotalColiforms;
      powerForm.genSpecCustomField = newVal.genSpecCustomField;
      powerForm.analysisCustomField = newVal.analysisCustomField;
      powerForm.micTestsCustomField = newVal.micTestsCustomField;
      powerForm.tableInputCustomField = newVal.tableInputCustomField;
      powerForm.tableInput1 = newVal.tableInput1;
      powerForm.tableInput2 = newVal.tableInput2;
      powerForm.tableInput3 = newVal.tableInput3;
      powerForm.tableInput4 = newVal.tableInput4;
      powerForm.tableInput5 = newVal.tableInput5;
      if (powerForm.genSpecCustomField) {
        this.genSpecCustomFieldAttr=JSON.parse(powerForm.genSpecCustomField)
      }
      if (powerForm.analysisCustomField) {
        this.analysisCustomFieldAttr=JSON.parse(powerForm.analysisCustomField)
      }
      if (powerForm.micTestsCustomField) {
        this.micTestsCustomFieldAttr=JSON.parse(powerForm.micTestsCustomField)
      }        
      if (powerForm.tableInputCustomField) {
        this.tableInputCustomFieldAttr=JSON.parse(powerForm.tableInputCustomField)
      }
      //#region 
      if (
        newVal.productType == "CSFMU-B" || newVal.productType == "CSFMU-S" || newVal.productType == "REXT" || 
        newVal.productType == "F&V" || newVal.productType == "FMU-D" || newVal.productType == "HEXT" || 
        newVal.productType == "OEXT" || newVal.productType == "PCG" || newVal.productType == "PCG-CSFMU-S" || 
        newVal.productType == "SDNP" || newVal.productType == "PCG-CSFMU-B"
      ) {
        powerForm.productType="Powder"
      }
      else if(newVal.productType == "Liquid-S" || newVal.productType == "Liquid-B" || newVal.productType == "SDNP-L" || 
      newVal.productType == "Liquid EXT-B" || newVal.productType == "Liquid EXT-S"){
        powerForm.productType="Liquid"
      }
      else if(newVal.productType == "SHXTO"){
        powerForm.productType="SHXTO"
      }
      else if(newVal.productType == "EHXTO"){
        powerForm.productType="EHXTO"
      }
      //#endregion
      //#endregion
      //#region 组装来自上传文件的内容
      powerForm.ChinaProductName = uploadItem.chinaProductName;
      powerForm.ChinaLotNumber = uploadItem.chinaLotNumber;
      powerForm.TotalNetWeight = uploadItem.totalNetWeight;
      powerForm.FreightSorting = uploadItem.freightSorting;

      powerForm.ShippingMethod = uploadItem.shippingMethod;
      powerForm.Quantity = uploadItem.quantityKG;
      powerForm.TyNumber = uploadItem.tyNumber;

      powerForm.BaseInfoProductName = newVal.productName;
      powerForm.BaseInfoItemCode = uploadItem.itemCode;
      powerForm.BaseInfoLot = uploadItem.lotNumber;

      powerForm.BaseInfoManufacturingDate = uploadItem.shippingDate;
      if(powerForm.BaseInfoManufacturingDate){
        powerForm.BaseInfoExpirationDate = base.methods.addDate(
          3,
          new Date(powerForm.BaseInfoManufacturingDate),
          "year"
        );
        powerForm.BaseInfoManufacturingDate = this.formatTime(
          powerForm.BaseInfoManufacturingDate,
          "yyyy-MM-dd"
        );
        powerForm.BaseInfoExpirationDate = this.formatTime(
          powerForm.BaseInfoExpirationDate,
          "yyyy-MM-dd"
        );
      }

      powerForm.ResultSTANDARD1 = uploadItem.standard1!=""?(parseFloat(uploadItem.standard1)*100).toFixed(2)+"%":uploadItem.standard1;
      powerForm.ResultSTANDARD2 = uploadItem.standard2;
      powerForm.ResultSTANDARD3 = uploadItem.standard3;
      powerForm.ResultMeshSize = uploadItem.meshSize;
      powerForm.ResultLossOnDrying = uploadItem.lossOnDrying!=""?(parseFloat(uploadItem.lossOnDrying)*100).toFixed(2)+"%":uploadItem.lossOnDrying;
      powerForm.ResultBulkDensity = uploadItem.bulkDensity;
      powerForm.ResultTotalHeavyMetals = uploadItem.totalHeavyMetals;
      powerForm.ResultColor = uploadItem.color;
      powerForm.ResultTotalPlateCount = uploadItem.totalPlateCount;
      powerForm.ResultYeastAndMold = uploadItem.yeastAndMold;
      powerForm.ResultCustomRowValue1 = uploadItem.specicalField1;
      powerForm.ResultCustomRowValue2 = uploadItem.specicalField2;
      powerForm.ResultCustomRowValue3 = uploadItem.specicalField3;
      powerForm.ResultCustomRowValue4 = uploadItem.specicalField4;
      powerForm.ResultCustomRowValue5 = uploadItem.specicalField5;
      //#endregion
      console.log("========powerForm========");
      console.log(powerForm);
      console.log(powerForm.productType);
      let jsonStr = JSON.stringify(powerForm);
      formData.productName = newVal.productName;
      formData.itemCode = uploadItem.itemCode;
      formData.lot = uploadItem.lotNumber;
      formData.jsonValue = jsonStr;
      return formData;
    },
    handlePdfClose(){
      this.dialogPdfVisible = false
    },
    showPdf(){
      if(this.selectCoaIdList.length!=1){
        this.$message({ type: "error", message: "请先从列表中选择一条数据！" });
      }
      else{
        this.dialogPdfVisible = true
        this.selectList.forEach(element=>{
          this.uploadPdf.itemCode = element.itemCode
          this.uploadPdf.lot = element.lot
          this.uploadPdf.id = element.id
          this.uploadPdf.productName = element.productName
        })
      }
    },
    onPdfChange(file) {
      console.log("======uploadFile=====");
      console.log(file);
      var filelist = new FormData();
      filelist.append("file", file.file);
      filelist.append("category", 1);
      this.$api
        .qiNiuUploadFile(filelist)
        .then((data) => {
          console.log('qiNiuUploadFile');
          console.log(data);
          this.pdfUrl = data
          this.$message({
            message: "file successfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("Network exception, please try again");
        });
    },
    onBeforePdfUpload(file) {
        //选择文件时限制格式
        console.log("======选择文件时限制格式=====");
        console.log(file);
        const isIMAGE = file.type == "application/pdf";
        if (!isIMAGE) {
            this.$message.error("上传文件只能是PDF格式!");
        }
        return isIMAGE;
    },
    addCoaToLibrary(){
      this.$confirm(
        "Are you sure add to Library. Continue?",
        "Tips",
        {
          confirmButtonText: "Ok",
          cancelButtonText: "Cancel",
          distinguishCancelAndClose: true,
          type: "warning",
        }
      ).then(() => {
        let jsonValue = JSON.stringify({pdfUrl:this.pdfUrl})
        let params = {
          ids:[],
          name:[this.uploadPdf.productName],
          type:'coa',
          inventoryType:1,
          jsonValue:jsonValue
        }
        params.ids = [this.uploadPdf.id]        
        console.log('addCoaToLibrary======params')
        console.log(params)
        this.$api.batchAddLibrary(params).then(() => {
          this.$message({ type: "success", message: "Add SuccessFully" });
          this.dialogPdfVisible = false
          this.$refs.pdfupload.clearFiles();
          this.uploadPdf = {
            itemCode:"",
            lot:"",
            id:0,
            productName:"",
          }
        })
        .catch((err) => {
          console.log(err);
        });
      });
    },
  },
  mounted() {
    this.getItemCodeListData({});
  },
  created() {
    // let type = localStorage.getItem("listDataTypeKey");
    // if (type == null || typeof type == "undefined") {
    //   this.dataType = "Spec";
    // } else {
    //   this.dataType = type;
    // }
    console.info('-----this.getCoaData(this.params);---')
    let searchDataCoaParams = localStorage.getItem("searchDataCoaParamsKey");
    if(searchDataCoaParams){
      console.info(searchDataCoaParams)
      this.params = JSON.parse(searchDataCoaParams);
      this.searchData = { name: this.params.productName, code: this.params.itemCode, lot: this.params.lot };
    }
    let params = { ...this.$route.query };
    if (params.itemCode) {
      this.params.itemCode = params.itemCode
      this.params.productName = ''
      this.params.lot = ''
      this.isShowBtn = false
    }
    if (params.productName) {
      this.params.itemCode = ''
      this.params.productName = params.productName
      this.params.lot = ''
      this.isShowBtn = false
    }
    if (params.lot) {
      this.params.itemCode = ''
      this.params.productName = ''
      this.params.lot = params.lot
      this.isShowBtn = false
    }
    this.getData(this.params);
    // this.getPreview();
    let sheight = window.screen.height;
    let swidth = window.screen.width;
    let ratio = swidth / sheight;
    switch (ratio) {
      case 16 / 9:
        this.screen_ratio = "ratio-16-9";
        break;
      case 16 / 10:
        this.screen_ratio = "ratio-16-10";
        break;
      case 3 / 2:
        this.screen_ratio = "ratio-3-2";
        break;
      case 4 / 3:
        this.screen_ratio = "ratio-4-3";
        break;
      default:
        this.screen_ratio = "ratio-16-10";
        break;
    }
  },
};
export const readFile = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (ev) => {
      resolve(ev.target.result);
    };
  });
};
</script>

<style lang="scss" scoped>
::v-deep .select-option {
  min-width: 300px;
}
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
.formSpan {
  text-align: right;
  float: left;
  font-size: 16px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  width: 120px;
}
.formInput {
  width: 55%;
  float: left;
  padding-bottom: 20px;
  margin-left: 10px;
}
</style>